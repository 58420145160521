@layer components {
  .banner-swiper,
  .banner-swiper .swiper-wrapper,
  .banner-swiper .swiper-wrapper .swiper-slide {
    @apply !h-full !w-full;
  }

  .timeline-swiper {
    @apply !overflow-visible;
  }

  .fiscal-swiper {
    @apply !mx-0 !overflow-hidden md:!mx-auto md:!overflow-visible;
  }

  @media screen and (max-width: 425px) {
    .fiscal-swiper {
      @apply !w-full;
    }
  }

  .timeline-swiper,
  .fiscal-swiper {
    @apply -mt-[68px] w-[90%] py-6 py-8;
    @apply sm:mt-0 sm:w-full;
  }

  .timeline-swiper,
  .fiscal-swiper .swiper-slide {
    @apply opacity-40;
  }

  @media screen and (max-width: 425px) {
    .timeline-swiper,
    .fiscal-swiper .swiper-slide {
      @apply !w-full;
    }
  }

  .timeline-swiper,
  .fiscal-swiper .swiper-slide.swiper-slide-active {
    @apply opacity-100;
  }

  .timeline-swiper,
  .fiscal-swiper .swiper-slide.swiper-slide-next {
    @apply sm:opacity-100;
  }

  .timeline-swiper,
  .fiscal-swiper .swiper-slide.swiper-slide-next + .swiper-slide {
    @apply lg:opacity-100;
  }

  .timeline-swiper,
  .fiscal-swiper .swiper-slide.swiper-slide-active h5 {
    @apply text-red;
  }

  .timeline-swiper,
  .fiscal-swiper .swiper-slide.swiper-slide-next h5 {
    @apply sm:text-red;
  }

  .timeline-swiper,
  .fiscal-swiper .swiper-slide.swiper-slide-next + .swiper-slide h5 {
    @apply lg:text-red;
  }

  .timeline-button-prev,
  .timeline-button-next,
  .button-prev,
  .button-next {
    @apply h-fit cursor-pointer rounded-full border px-6 py-4 text-2xl  shadow-button-nav;
    @apply border-neutral-placeholder bg-white text-neutral-placeholder;
    @apply hover:border-red hover:bg-red hover:text-white;
  }

  .fiscal-button-prev,
  .fiscal-button-next,
  .non-fiscal-button-prev,
  .non-fiscal-button-next {
    @apply flex h-11 w-15 cursor-pointer items-center justify-center rounded-full border border-red text-2xl text-red shadow-button-nav lg:hover:bg-red lg:hover:text-white;
  }

  .fiscal-button-prev,
  .non-fiscal-button-prev,
  .timeline-button-prev,
  .button-prev {
    @apply rotate-180;
  }

  .timeline-button-prev.swiper-button-disabled,
  .timeline-button-next.swiper-button-disabled,
  .button-prev.swiper-button-disabled,
  .button-next.swiper-button-disabled,
  .booklet-button-prev.swiper-button-disabled,
  .booklet-button-next.swiper-button-disabled {
    @apply cursor-not-allowed border-neutral-placeholder bg-neutral-border text-neutral-placeholder;
  }

  .fiscal-button-prev.swiper-button-disabled,
  .fiscal-button-next.swiper-button-disabled,
  .non-fiscal-button-prev.swiper-button-disabled,
  .non-fiscal-button-next.swiper-button-disabled {
    @apply cursor-not-allowed border-neutral-placeholder text-neutral-placeholder lg:hover:bg-transparent;
  }

  .swiper-highlight-image .swiper-wrapper .swiper-slide {
    height: auto !important;
  }

  .swiper-detail-image {
    height: 100% !important;
  }

  .swiper-detail-image .swiper-wrapper .swiper-slide {
    height: auto !important;
  }

  .swiper-detail-image .swiper-wrapper .swiper-slide .detail-image-wrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%) !important;
    border-radius: 8px;
  }

  .swiper-detail-image .swiper-wrapper .swiper-slide-thumb-active .detail-image-wrapper {
    background: transparent !important;
    border-radius: 8px;
  }

  .swiper.swiper-booklet {
    overflow: unset;
  }

  .swiper-booklet .swiper-wrapper .swiper-slide {
    height: auto !important;
  }
}
