.ppid-page-clip {
  @apply before:z-[1];
  @apply before:absolute before:-top-[26.82px] before:right-[76.82px];
  @apply before:clip-para before:h-[243.26px] before:w-[276.35px] before:bg-orange;
  @apply relative right-0 top-0 flex h-full justify-end;
  @apply md:absolute md:before:h-full;
  @apply lg:before:-top-12.5 lg:before:right-[125px] lg:before:w-[368px];
  @apply xl:before:w-[568px];
}

.ppid-page-image {
  @apply clip-para relative z-[2] h-[243px] w-[335px] object-cover object-left md:h-full lg:w-[456px] xl:w-[656px];
}

.ppid-form-request-information {
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #d9d9d9 96.26%);
}

.ppid-form-banner {
  background-position: 0 -661px;
}

.ppid-form-banner-survey {
  background-position: 0 -661px;
}

.shortcut-link-text {
  @apply flex w-[173px] flex-col items-start gap-3 text-left text-sm font-bold text-gray-1;
  @apply after:block after:w-6 after:border-b after:border-b-gray-1 after:transition-all after:duration-500;
  @apply lg:mb-3 lg:w-auto lg:items-center lg:text-center lg:text-xl lg:group-hover:after:w-[120px];
}

@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  .ppid-page-clip {
    @apply xl:before:w-[468px];
  }

  .ppid-page-image {
    @apply xl:w-[556px];
  }

  .ppid-form-banner {
    background-position: 0 -570px;
  }

  .ppid-form-banner-survey {
    background-position: 0 -570px;
  }
}

@media only screen and (max-width: 768px) {
  .ppid-form-banner {
    background-position: 0;
  }

  .ppid-form-banner-survey {
    background-position: 0 -570px;
  }
}

@media only screen and (max-width: 375px) {
  .shortcut-link-text {
    @apply w-[173px];
  }
}

@media only screen and (min-width: 414px) and (max-width: 425px) {
  .shortcut-link-text {
    @apply w-[200px];
  }
}
