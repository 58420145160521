@layer components {
  .description ul,
  ol,
  menu {
    list-style: revert !important;
    margin: revert !important;
    padding: revert !important;
  }

  .ppn-description ul,
  .ppn-description ol {
    list-style: revert !important;
    padding: 0 0 0 16px !important;
    margin: 0 !important;
  }
}
