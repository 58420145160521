.swiper-main-container {
  @apply relative mt-7 h-[229px] w-full md:mt-6 md:h-[400px] lg:h-[517px];
}
.swiper-main-pagination {
  @apply mt-4 h-[71px] w-full md:mt-6 lg:h-24 lg:px-9 xl:px-[95px];
}

@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  .swiper-main-container {
    @apply h-[317px];
  }
  .swiper-main-pagination {
    @apply h-18;
  }
}
