@layer components {
  .floating-container {
    /*mobile*/
    @apply fixed bottom-0 z-[70] flex w-[100svw] divide-x-2;
    /*mobile*/

    @apply lg:bottom-auto lg:right-2.5 lg:top-[226px] lg:w-auto lg:divide-x-0;
    @apply items-end lg:flex-col lg:gap-y-2;
  }

  .floating__item-container {
    @apply relative flex flex-1;
    @apply lg:flex-none;
  }

  .floating__item-container:hover {
    @apply flex;
  }

  .floating__item-container:hover .floating__item-text-outside {
    @apply transition-opacity duration-500 ease-in-out;
    @apply lg:flex lg:items-center lg:justify-center;
    @apply lg:w-full lg:px-4 lg:opacity-100;
    @apply lg:rounded-l-lg lg:rounded-r-none lg:bg-black/[0.3];
  }

  .floating__item-container:hover .floating__item {
    @apply lg:transition-colors lg:duration-500 lg:ease-in-out;
    @apply lg:flex-grow;
    @apply lg:rounded-l-none lg:rounded-r-lg lg:bg-black/[0.3];
    @apply lg:max-w-full;
  }

  .floating__item-text-outside {
    @apply hidden;
    @apply lg:block lg:w-0 lg:opacity-0;
    @apply lg:text-sm lg:text-white;
  }

  .floating__item {
    /*mobile*/
    @apply flex h-20 flex-1 flex-col-reverse items-center gap-y-1.5;
    @apply bg-white px-2 pb-[7px] pt-3;
    @apply text-[10px] font-medium;
    /*mobile*/
    /*desktop*/
    @apply lg:flex-none lg:flex-row lg:items-center lg:justify-center;
    @apply lg:h-16 lg:min-w-[64px] lg:max-w-min lg:rounded-lg lg:bg-black/[0.1] lg:p-4;
    /*desktop*/
  }

  .floating__item-icon {
    /*mobile*/
    @apply h-6 w-6 rounded-full;
    /*mobile*/
    /*desktop*/
    @apply lg:h-8 lg:w-8;
    /*desktop*/
  }

  .floating__item .floating__item-text {
    @apply h-7.5 text-center;
    @apply lg:hidden;
  }

  .floating__item a {
    @apply absolute inset-0;
  }
}
