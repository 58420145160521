.hti-link {
  @apply relative flex items-center gap-4 overflow-hidden rounded-lg border border-neutral-border bg-neutral-disable px-3 py-3.5 transition-colors duration-500 hover:bg-red hover:text-white;
}
.hti-link-icon {
  @apply text-[32px] text-red;
}
.hti-link-circle {
  @apply absolute -right-[27px] flex h-[92px] w-[92px] items-center rounded-full bg-transparent transition-colors duration-500;
}

.hti-icon-open {
  @apply ml-[25px] text-2xl text-transparent transition-colors duration-500;
}

.hti-link:hover .hti-link-icon {
  @apply text-white;
}

.hti-link:hover .hti-link-circle {
  @apply bg-[#A63535];
}

.hti-link:hover .hti-link-circle {
  @apply bg-[#A63535];
}

.hti-link:hover .hti-icon-open {
  @apply text-yellow;
}
