@layer utilities {
  .clip-para {
    clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);
  }
  .clip-para-2 {
    clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
  }
  .clip-para-reverse {
    clip-path: polygon(0 0, 85% 0%, 100% 100%, 0% 100%);
  }
  .clip-triangle {
    clip-path: polygon(0 0, 0% 100%, 100% 0);
  }
  .clip-about-member-image {
    clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
  }
  .clip-how-to-invest-ornament {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
  }
  .clip-how-to-invest-ornament-before {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 78.5%);
  }
  .clip-why-invest-banner {
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  }
}
