@layer components {
  .footer {
    @apply w-full overflow-hidden border-t-[6px] border-t-red;
  }
  .footer__container {
    @apply relative h-auto bg-white py-4;
    @apply lg:h-[485px] lg:py-[60px];
  }
  .footer__main-container {
    @apply container relative z-20 space-y-6;
  }

  .footer__section-one-container {
    @apply flex flex-col gap-4;
    @apply lg:flex-row lg:gap-x-6;
    @apply lg:text-sm;
  }

  .footer__section-item__icon {
    @apply;
  }

  .footer__address {
    @apply w-full;
    @apply lg:w-fit lg:max-w-[622px] lg:flex-grow;
  }

  .footer__address-item {
    @apply flex w-full gap-x-3 align-baseline text-sm;
    @apply lg:w-[586px];
  }

  .footer__address-item__title {
    @apply text-xs font-medium text-gray-2;
    @apply lg:w-[220px] lg:text-sm;
  }

  .footer__phone-and-email-container {
    @apply flex w-full flex-grow gap-x-4;
    @apply lg:w-[calc(45%-24px)] lg:gap-x-6;
  }

  .footer__section-two-container {
    @apply flex flex-col justify-between gap-y-6;
    @apply lg:flex-row;
  }

  .footer__section-two-items {
    @apply flex w-full gap-x-3 align-baseline;
    @apply lg:w-[220px];
  }

  .footer__section-two-items__title {
    @apply text-xs font-medium text-gray-2;
    @apply lg:w-[220px] lg:text-sm;
  }
  .footer__section-two-items a {
    @apply lg:text-sm;
  }

  .footer__description {
    @apply w-full space-y-4 text-xs;
    @apply lg:w-[350px] lg:space-y-6 lg:text-sm;
  }

  .footer__description .footer__description-logo {
    @apply h-auto w-[144px];
    @apply lg:w-36;
  }

  .footer__description-links {
    @apply flex gap-x-3;
  }

  .footer__links-container {
    @apply flex w-full flex-wrap justify-between gap-y-4;
    @apply lg:w-[calc(60%-16px)] lg:flex-nowrap;
  }

  .footer__links {
    @apply w-[calc(50%-16px)];
    @apply lg:w-[calc(26%-16px)];
  }

  .footer__section-links-title {
    @apply mb-5 text-sm font-bold;
    @apply lg:text-base;
  }

  .footer__links-list {
    @apply space-y-6 text-sm;
  }

  .footer__links-list > li > a:hover {
    @apply text-red;
  }

  .footer__links-list > li > a:active {
    @apply text-black;
  }

  .footer__bg-maps {
    @apply bg-none;
    @apply bg-contain bg-center bg-no-repeat lg:absolute lg:inset-0 lg:block lg:overflow-hidden lg:bg-footer;
  }

  .footer__copy-right {
    @apply flex h-[153px] justify-center bg-yellow px-4 py-6 text-center text-xs;
    @apply md:h-[145px];
    @apply lg:h-[72px] lg:items-center lg:text-base;
  }
}
