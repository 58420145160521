@font-face {
  font-family: 'kek';
  src: url('fonts/kek.eot?hhqd2y');
  src: url('fonts/kek.eot?hhqd2y#iefix') format('embedded-opentype'), url('fonts/kek.ttf?hhqd2y') format('truetype'),
    url('fonts/kek.woff?hhqd2y') format('woff'), url('fonts/kek.svg?hhqd2y#kek') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='kek-'],
[class*=' kek-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'kek' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kek-option:before {
  content: '\e935';
}
.kek-filter:before {
  content: '\e934';
}
.kek-open:before {
  content: '\e933';
}
.kek-mail:before {
  content: '\e900';
}
.kek-call:before {
  content: '\e901';
}
.kek-location:before {
  content: '\e902';
}
.kek-chat-bubble-question:before {
  content: '\e903';
}
.kek-chevron-left:before {
  content: '\e904';
}
.kek-play:before {
  content: '\e905';
}
.kek-arrow-right:before {
  content: '\e906';
}
.kek-close:before {
  content: '\e907';
}
.kek-hamburger:before {
  content: '\e908';
}
.kek-tourism:before {
  content: '\e909';
}
.kek-manufacturing:before {
  content: '\e90a';
}
.kek-service:before {
  content: '\e90b';
}
.kek-briefcase:before {
  content: '\e90c';
}
.kek-arrow-download:before {
  content: '\e90d';
}
.kek-pdf:before {
  content: '\e90e';
}
.kek-chevron-right:before {
  content: '\e90f';
}
.kek-shake:before {
  content: '\e910';
}
.kek-operation:before {
  content: '\e911';
}
.kek-agreement:before {
  content: '\e912';
}
.kek-info-circle:before {
  content: '\e913';
}
.kek-web:before {
  content: '\e914';
}
.kek-arrow-up-right:before {
  content: '\e915';
}
.kek-search:before {
  content: '\e916';
}
.kek-arrow-up-s-line:before {
  content: '\e917';
}
.kek-arrow-down-s-line:before {
  content: '\e918';
}
.kek-industry:before {
  content: '\e919';
}
.kek-shield-task:before {
  content: '\e91a';
}
.kek-auction:before {
  content: '\e91b';
}
.kek-engineer:before {
  content: '\e91c';
}
.kek-map:before {
  content: '\e91d';
}
.kek-group:before {
  content: '\e91e';
}
.kek-document-approved:before {
  content: '\e91f';
}
.kek-document-signature:before {
  content: '\e920';
}
.kek-rocket:before {
  content: '\e921';
}
.kek-map-2:before {
  content: '\e922';
}
.kek-no-tax:before {
  content: '\e923';
}
.kek-info-shield:before {
  content: '\e924';
}
.kek-folder-open:before {
  content: '\e925';
}
.kek-document-bullet-list:before {
  content: '\e926';
}
.kek-eye:before {
  content: '\e928';
}
.kek-document:before {
  content: '\e929';
}
.kek-document-legal:before {
  content: '\e92a';
}
.kek-info-circle-solid:before {
  content: '\e92b';
}
.kek-calendar:before {
  content: '\e92c';
}
.kek-check:before {
  content: '\e92d';
}
.kek-document-survey:before {
  content: '\e92e';
}
.kek-document-pen-a-side:before {
  content: '\e92f';
}
.kek-document-information:before {
  content: '\e930';
}
.kek-user:before {
  content: '\e931';
}
.kek-document-ribbon:before {
  content: '\e932';
}
.kek-video-clip:before {
  content: '\e927';
}
