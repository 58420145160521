@layer components {
  .btn {
    @apply w-fit cursor-pointer rounded-full px-3.5 py-3 text-center font-bold;
    @apply disabled:bg-form-disable-background disabled:text-action-disabled;
    @apply transition-all duration-500;
  }

  .btn--pill {
    @apply rounded-full;
  }
}
