.gm-ui-hover-effect {
  display: none !important;
}

.gm-style .gm-style-iw {
  padding: 12px !important;
  max-width: 280px !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
  padding: 0 !important;
}
