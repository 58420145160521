@layer utilities {
  .animation-delay-75 {
    animation-delay: 75ms;
  }
  .animation-delay-100 {
    animation-delay: 100ms;
  }
  .animation-delay-300 {
    animation-delay: 300ms;
  }
  .animation-delay-500 {
    animation-delay: 500ms;
  }
  .animation-delay-1000 {
    animation-delay: 1s;
  }
}
