.card-border-small {
  @apply absolute -right-[34px] -top-[90px] z-20 size-[136px] rounded-full border-[10px] opacity-25;
  @apply md:-right-[70px] md:-top-[137px] md:size-[202px];
  @apply lg:-right-[70px] lg:-top-[135px];
}

.card-border-big {
  @apply absolute -right-[160px] -top-[54px] z-20 size-[202px] rounded-full border-[10px] opacity-25;
  @apply md:-right-[272px] md:-top-[100px] md:size-[300px];
  @apply lg:-right-[269px] lg:-top-[88px];
}

.banner-container {
  @apply relative flex h-[calc(100vh-75px)] items-center justify-center overflow-hidden lg:w-full lg:justify-start lg:bg-orange lg:pl-[84px];
}
.map-card-type-first-gap {
  @apply gap-3.5 md:gap-4 md:py-[88px] lg:gap-6 lg:py-0;
}

.map-card-type-first-card-container {
  @apply md:flex-1 lg:flex-none;
}

.map-card-type-first-ornament {
  @apply absolute -left-[16.93px] -top-[38px] z-0 h-[140px] w-[123.776px] md:top-0 lg:-left-[16.93px] lg:-top-[52px];
}

.map-card-type-second-gap {
  @apply gap-3 md:gap-4.5 md:py-[54.5px] lg:gap-6 lg:py-0;
}

.map-card-type-second-card-container {
  @apply md:flex-1 lg:flex-none;
}

.map-card-type-second-ornament {
  @apply absolute -left-[33px] -top-[59px] z-0 size-[156px] md:-top-[17px] lg:-left-[16.93px] lg:-top-[52px];
}

.map-card-type-third-gap {
  @apply gap-3 md:py-4 lg:gap-6 lg:py-0;
}

.map-card-type-third-card-container {
  @apply py-4 md:flex-1 md:py-0 lg:flex-none;
}

.map-card-type-forth-gap {
  @apply gap-3 md:gap-4 md:py-6 lg:gap-6 lg:py-0;
}

.map-card-type-forth-card-container {
  @apply md:flex-1 lg:flex-none lg:py-0;
}

.map-card-type-forth-ornament {
  @apply absolute -left-[23px] -top-[42px] z-0 h-[93px] w-[118px] lg:-left-[16.93px] lg:-top-[62px] lg:h-[141px] lg:w-[127px];
}

.banner-first-container {
  @apply before:clip-why-invest-banner before:absolute before:-left-10 before:bottom-10 before:top-0 before:z-[1];
  @apply before:w-[654px] before:bg-orange-card;
  @apply absolute bottom-0 right-30 top-0 hidden justify-end lg:flex;
  @apply md:absolute;
}

.banner-first-images {
  @apply clip-why-invest-banner relative z-[2] w-[654px] object-cover object-left;
}

.banner-second-container {
  @apply before:clip-why-invest-banner before:absolute before:-bottom-10 before:-right-10 before:z-[1] before:h-[471px];
  @apply before:w-[477px] before:bg-orange-card;
  @apply md:absolute;
  @apply absolute bottom-[80px] right-[-267px] hidden justify-end lg:flex;
}

.banner-second-images {
  @apply clip-why-invest-banner relative z-[2] h-[471px] w-[477px] object-cover object-center;
}

.banner-ornament {
  @apply absolute right-[84px] top-[50px] z-10 hidden lg:block;
}

.banner-ornament-image {
  @apply h-20 w-[210px];
}

.map-tabs {
  @apply absolute left-1/2 top-2.5 z-10 inline-flex w-full -translate-x-1/2 gap-x-3;
  @apply md:left-5 md:w-auto md:translate-x-0;
  @apply lg:left-11 lg:top-2.5;
}

.map-legends {
  @apply absolute bottom-0 right-20 w-auto md:w-[703px] lg:bottom-11 xl:bottom-10;
  @apply md:w-[703px];
  @apply lg:bottom-5 lg:right-11;
}

.map-card-position {
  @apply relative bottom-0 left-0 m-4 overflow-hidden rounded-[20px] md:mx-5 md:my-4 lg:absolute lg:bottom-5 lg:left-20 lg:m-0;
  @apply lg:bottom-5 lg:left-11;
}

.map-card-container {
  @apply relative flex h-auto min-h-[370px] w-full flex-col justify-between overflow-hidden rounded-[20px] bg-white pt-6;
  @apply md:min-h-[439px] md:flex-row md:pt-0;
  @apply lg:max-h-[555px] lg:min-h-[526px] lg:w-[408px] lg:flex-col lg:pt-4;
}

.map-card-strategic-location {
  @apply flex flex-col gap-6 px-6;
}

.map-card-title {
  @apply text-xl leading-[24px] md:text-2xl lg:w-[320px] lg:text-[34px];
}

.map-card-description {
  @apply text-xs leading-[18px] md:text-sm md:leading-[21px] lg:text-base lg:leading-[24px] xl:leading-[30px];
}

.map-card-population-item {
  @apply flex gap-4 rounded-lg bg-[#f7f7f7]/40 p-3 backdrop-blur-[21px] transition-all duration-300;
}

@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  .banner-first-container {
    @apply right-[126px] top-0 before:bottom-10 before:top-0 before:h-[620px] before:w-[424px];
  }

  .banner-first-images {
    @apply h-full w-[424px];
  }

  .banner-second-container {
    @apply bottom-7 before:-bottom-3 before:w-[427px];
  }

  .banner-second-images {
    @apply w-[427px] object-fill;
  }

  .banner-ornament {
    @apply top-5;
  }

  .map-card-description {
    @apply text-sm leading-[21px];
  }

  .map-card-strategic-location {
    @apply gap-3;
  }

  .map-card-type-first {
    @apply gap-3;
  }

  .map-card-type-first-ornament {
    @apply lg:-left-[16.93px] lg:-top-[46px];
  }

  .map-card-type-second-ornament {
    @apply lg:-left-[27px] lg:-top-[60px];
  }

  .map-card-type-first-gap {
    @apply lg:gap-3;
  }

  .map-card-type-forth-gap {
    @apply lg:gap-4;
  }

  .map-card-title {
    @apply w-[230px] text-[24px];
  }

  .map-card-population-item {
    @apply flex gap-4 rounded-lg bg-[#f7f7f7]/40 px-3 py-4 backdrop-blur-[21px] transition-all duration-300;
  }
}
